export default {
  apiURL: {
    salesforceAccount: 'salesforce/account',
    salesforceAccountOpportunity: 'salesforce/account-opportunity',
    salesforceAccountDetails: 'salesforce/account-details',
    salesforceLedgerEntries: 'salesforce/account-ledger-entries',
    salesforceInvoicePayment: 'salesforce/account-invoice-payment',
    salesforceAccountHistory: 'salesforce/account-history',
    salesforceSalesOrders: 'salesforce/account-sales-orders',
    salesforceSalesHistory: 'salesforce/account-sales-history',
    salesforceOpportunityDetail: 'salesforce/opportunity-details',
    salesforceOpportunitySalesOrders: 'salesforce/opportunity-sales-orders',
    salesforceOpportunitySalesHistory: 'salesforce/opportunity-sales-history',
    salesforceOpportunityProducts: 'salesforce/opportunity-products',
    salesforceOpportunityLedgers: 'salesforce/opportunity-ledger-entries',
    salesforceOpportunityNotes: 'salesforce/opportunity-notes',
    salesforceOpportunityStageHistory: 'opportunity-stage-history',
    salesforceOpportunityQuotes: 'salesforce/opportunity-quotes',
    salesforceOpportunityInvoices: 'salesforce/opportunity-invoice',
    salesforceOpportunityFiles: 'salesforce/opportunity-files',
    salesforceOpportunityInvoicePayments: 'salesforce/opportunity-invoice-payment',
    salesforceOpportunityRequestForSalesProposal: 'salesforce/opportunity-request-sales-proposal',
    salesforceOpportunityGetProducts: 'salesforce/opportunity-getproducts',
    salesforceCampaignDropdown: '/campaign-dropdown',
    salesforceDropdownOptions: '/dropdown-options',
    salesforceOpportunityCampaign: '/campaign-list',
    salesforceCampaignHistoryDetail: '/campaign-history-details',
    salesforceMediaHistoryDetail: '/media-plan-history-details',
    opportunityPaymentCalenders: '/opportunity/{0}/paymentCalendars',
    renewPaymentCalenders: '/paymentCalendars/{0}/renew',
    paymentCalendars: '/paymentCalendars',
    campaignPaymentCalenders: '/opportunity-campaign/payment-calendar-link/{0}',
    pendingApprovals: '/pending-approvals/{0}',
    pendingCampaignApprovals: '/campaign-pending-request/{0}',
    pendingApprovalsApprove: '/pending-approvals/{0}/approve',
    pendingApprovalsReject: '/pending-approvals/{0}/disapprove',
    approvalHistory: '/pending-approvals',
    campaign: {
      global: 'campaign',
      list: 'campaign-filter',
      upsell: {
        opportunities: 'upsellOpportunitiesByAccount',
        update: 'updateCampaignBudgetByUpsell',
      },
      retain: {
        opportunities: 'retainOpportunitiesByAccount',
        update: 'updateCampaignBudgetByRetain',
      },
      solutionPlan: {
        channelCycle: 'channel-cycle',
        campaignGoogleFB: 'manual-sync-campaigns',
      },
    },
    salesforceCampaignProductCategoryDropdown: '/campaign-product-category',
    salesforceCampaignProductProductList: 'campaign-category-product',
    salesforceCampaignProductProductTable: 'campaign-product',
    salesforceCampaignData: '/salesforce-campaign-data',
    salesforceCampaign: 'campaign',
    exportCampaign: 'campaigns',
    updateRetainCampaign: '/retain-campaign',
    salesforceCampaignHistory: '/campaign-history',
    salesforceCampaignPendingRequestExist: '/campaign-pending-request-exist',
    autoSync: '/auto-sync-campaign',
    domain: {
      list: 'domains',
      export: 'domains/export',
      count_status: 'domains/count-status',
    },
    salesforceRetainCampaignDetail: 'retain-opportunity-campaign-detail',
    campaignCreativeBrief: {
      questions: 'campaign-creative-brief/questions',
      list: opportunityId => `campaign-creative-brief/list/${opportunityId}`,
      filteredList: opportunityId => `campaign-creative-brief/dropdown-options/${opportunityId}`,
      show: (opportunityId, creativeBriefingId) => `campaign-creative-brief/${opportunityId}/${creativeBriefingId}`,
      create: 'campaign-creative-brief',
      update: creativeBriefingId => `campaign-creative-brief/${creativeBriefingId}`,
      editable: creativeBriefingId => `campaign-creative-brief/editable/${creativeBriefingId}`,
      assign: creativeBriefingId => `campaign-creative-brief/assign/${creativeBriefingId}`,
      history: creativeBriefingId => `campaign-creative-brief/dropdown-options/version/${creativeBriefingId}`,
      export: creativeBriefingId => `campaign-creative-brief/export/${creativeBriefingId}`,
    },
  },
  kpiUnitOptions: [
    { text: 'Leads', value: 'leads' },
    { text: 'Views', value: 'views' },
    { text: 'Clicks', value: 'clicks' },
    { text: 'Impressions', value: 'impressions' },
    { text: 'Messages', value: 'messages' },
    { text: 'Engagements', value: 'engagements' },
    { text: 'Reaches', value: 'Reaches' },
    { text: 'Pieces', value: 'pieces' },
    { text: 'Page Likes', value: 'pagelikes' },
    { text: 'Ad Recall Lifts', value: 'adrecalllifts' },
    { text: 'Purchases', value: 'purchases' },
    { text: 'No KPI', value: 'nokpi' },
    { text: 'Register', value: 'register' },
  ],
  productStatusOptions: {
    Onboarding: 'Onboarding',
    Active: 'Active',
    Paused: 'Paused',
    Inactive: 'Inactive',
    OnHold: 'On Hold',
  },
  productStatusList: [
    { text: 'Onboarding', value: 'Onboarding' },
    { text: 'Active', value: 'Active' },
    { text: 'Paused', value: 'Paused' },
    { text: 'Inactive', value: 'Inactive' },
  ],

  campaignDotStatuses: [
    {
      id: '5b63bbb85e01bb69486957a2',
      name: 'Active',
      type: 'success',
    },
    {
      id: '5c0f5be9905b810d44140a72',
      name: 'Onboarding',
      type: 'info',
    },
    {
      id: '5b63bbb85e01bb69486957a3',
      name: 'Paused',
      type: 'warning',
    },
    {
      id: '98d2e018-9ae6-4611-8f54-9b316b3086d2',
      name: 'On Hold',
      type: 'primary',
    },
    {
      id: '98d2e018-99a1-4660-96df-076490943f2c',
      name: 'Lapsed',
      type: 'danger',
    },
    {
      id: '98d2e018-9962-4d4a-9194-aedb9891e29e',
      name: 'Retained',
      type: 'danger',
    },
    {
      id: '98d2e018-98cd-4dd8-baa8-f0428ac0bba1',
      name: 'Pause ( Invoice Change)',
      type: 'warning',
    },
    {
      id: '98d2e018-9923-4e32-91fe-b0dcb71fddc9',
      name: 'Pause ( No invoice Change)',
      type: 'warning',
    },
    {
      id: '98d2e018-9a9b-441a-863e-a17279775d95',
      name: 'Paused Campaign',
      type: 'info',
    },
    {
      id: '5c32f8e1905b81684e2c2456',
      name: 'Inactive',
      type: 'danger',
    },
    {
      id: '98d2e018-99e7-4cfd-84fa-599350a34724',
      name: 'Churn - Broke Contract',
      type: 'danger',
    },
    {
      id: '98d2e018-9a3d-4f4d-ae92-1219c0fa4d1e',
      name: 'Churn - Contract end without renew',
      type: 'danger',
    },
  ],
}
