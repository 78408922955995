export default [
  {
    path: '/unmapped-leads',
    name: 'unmapped-leads',
    component: () => import('@/views/unmapped-leads/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Unmapped Leads',
      breadcrumb: [
        {
          text: 'Unmapped Leads',
          active: true,
          to: 'unmapped-leads',
        },
      ],
    },
  },
  {
    path: '/lead-generation-th',
    name: 'lead-generation-th',
    component: () => import('@/views/lead-generation/LeadGenerationTh.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Lead Generation (TH)',
      breadcrumb: [
        {
          text: 'Lead Generation (TH)',
          active: true,
          to: 'lead-generation-th',
        },
      ],
      resource: 'Lead Generation (TH)',
      action: 'Access',
    },
  },
  {
    path: '/tiktok/lead-generation/index',
    name: 'tiktok-lead-generation-index',
    component: () => import('@/views/tiktok/lead-generation/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'TikTok Lead Generation',
      breadcrumb: [
        {
          text: 'TikTok Lead Generation',
          active: true,
          to: 'tiktok-lead-generation-index',
        },
      ],
      resource: 'TikTok Lead Generation',
      action: 'Access',
    },
  },
  {
    path: '/lead-service/support',
    name: 'lead-service-support',
    component: () => import('@/views/lead-service-support/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Lead Service Support',
      breadcrumb: [
        {
          text: 'Lead Service Support',
          active: true,
          to: 'lead-service-support',
        },
      ],
      resource: 'Lead Service Support',
      action: 'Access',
    },
  },
  {
    path: '/ads-campaign-syncing',
    name: 'ads-campaign-syncing',
    component: () => import('@/views/ads-campaign-syncing/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ads Campaign Syncing',
      breadcrumb: [
        {
          text: 'Ads Campaign Syncing',
          active: true,
          to: 'ads-campaign-syncing',
        },
      ],
      resource: 'Ads Campaign Syncing',
      action: 'Access',
    },
  },
]
