import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/named
import { array, autoLoad } from '@/utils/helper'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from '@/store/app'
import appConfig from '@/store/app-config'
import verticalMenu from '@/store/vertical-menu'

import heroAiAuthentications from '@/store/heroai-authentications'
import Permissions from '@/store/heroai/settings/Permissions'
import CreateCampaign from '@/store/heroai/CreateCampaign'

// Outsource Code
import heroAiCampaign from '@/store/heroai-campaign'
import CampaignDetail from '@/store/heroai/campaign/Detail'
// eslint-disable-next-line import/no-cycle
import channel from '@/store/channel/index'

// Objects
import AccountOptions from '@/store/services/AccountOptions'
import BranchOptions from '@/store/services/BranchOptions'
import BranchTypeOptions from '@/store/services/BranchTypeOptions'
import HeroVisionCampaignOption from '@/store/services/HeroVisionCampaignOption'
import CampaignOptions from '@/store/services/CampaignOptions'
import ChannelOptions from '@/store/services/ChannelOptions'
import AnalyticCampaignOptions from '@/store/services/AnalyticCampaignOptions'
import ClientOptions from '@/store/services/ClientOptions'
import CountryOptions from '@/store/services/CountryOptions'
import GenderOptions from '@/store/services/GenderOptions'
import GroupOptions from '@/store/services/GroupOptions'
import KpiUnitOptions from '@/store/services/KpiUnitOptions'
import LanguageOptions from '@/store/services/LanguageOptions'
import MetricUnitOptions from '@/store/services/MetricUnitOptions'
import OpportunityOptions from '@/store/services/OpportunityOptions'
import OpportunityOptionsWalletPage from '@/store/services/OpportunityOptionsWalletPage'
import PermissionOptions from '@/store/services/PermissionOptions'
import ProductFamilyOptions from '@/store/services/ProductFamilyOptions'
import ProductOptions from '@/store/services/ProductOptions'
import RoleOptions from '@/store/services/RoleOptions'
import SupervisorOptions from '@/store/services/SupervisorOptions'
import UserLevelOptions from '@/store/services/UserLevelOptions'
import UserPositionOptions from '@/store/services/UserPositionOptions'

// Wallet Budget
import DestinationWalletBudgetOptions from '@/store/services/DestinationWalletBudgetOptions'
import SourceWalletBudgetOptions from '@/store/services/SourceWalletBudgetOptions'

// HeroVision
import LeadManagement from '@/store/herovision/LeadManagement'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    heroAiAuthentications,
    Permissions,
    CreateCampaign,

    // Outsource Code
    heroAiCampaign,
    CampaignDetail,
    channel,

    // Objects
    AccountOptions,
    BranchOptions,
    BranchTypeOptions,
    HeroVisionCampaignOption,
    CampaignOptions,
    ChannelOptions,
    AnalyticCampaignOptions,
    ClientOptions,
    CountryOptions,
    GenderOptions,
    GroupOptions,
    KpiUnitOptions,
    OpportunityOptions,
    OpportunityOptionsWalletPage,
    MetricUnitOptions,
    LanguageOptions,
    PermissionOptions,
    ProductFamilyOptions,
    ProductOptions,
    RoleOptions,
    SupervisorOptions,
    UserLevelOptions,
    UserPositionOptions,

    // Wallet Budget
    DestinationWalletBudgetOptions,
    SourceWalletBudgetOptions,

    // HeroVision
    LeadManagement,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
  getters: {
    getMap: state => (mapName, directly = false) => {
      const foundModules = []
      Object.keys(state).forEach(moduleName => {
        Object.keys(state[moduleName]).forEach(moduleMapName => {
          if (moduleMapName.toLowerCase() === mapName.toLowerCase()) {
            foundModules.push(moduleName)
          }
        })
      })
      if (foundModules.length === 0) {
        console.warn(`CAN NOT FIND MODULE THAT HAVE MAP <${mapName}>`)
      }
      if (foundModules.length > 1) {
        console.warn(`MORE THAN ONE MODULE CONTAIN SAME MAP <${mapName}>, NEED TO REMOVE`, foundModules)
      }

      const module = state[foundModules[0]]
      const map = module[mapName]

      if (directly) return map

      autoLoad(map, mapName, module)

      return map
    },
    getArray: (state, getters) => (mapName, directly = false) => {
      const map = getters.getMap(mapName, directly)
      return array(map)
    },
    getObject: (state, getters) => (value, mapName, property = 'id') => {
      if (!value && value !== 0) return null

      const list = getters.getArray(mapName)
      return list.find(object => object[property] === value)
    },
  },
})
